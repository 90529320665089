<template>
  <invite-user v-if="dataList.showInviteForm" 
    :set-url="dataList.inviteUserUrl" 
    :params="dataList.inviteUserParams"
    :type="dataList.invitationType" 
    :company-id="selectedCompany.id" 
    :role="dataList.inviteUserParams.role_id"
    :roleName="dataList.inviteUserParams.role_name"
    @approverGroupSuccess="approverGroupSuccess" />
  <section v-else>
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
        <div class="row scrollable-content">
          <div :class="$route.name === 'create-template'
              ? 'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3'
              : 'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'
            ">
            <div class="table-box p-3 mb-3">
              <div class="
                  table-title
                  d-flex
                  flex-xxl-row
                  flex-xl-row
                  flex-lg-row
                  flex-md-row
                  flex-sm-column
                  flex-column
                  mb-3
                ">
                <div class="flex-grow-1 my-auto">
                  <h2 class="
                      mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3
                      border-bottom
                      pb-2
                    ">
                    {{
                      $route.name === "create-template"
                      ? "Create New Template"
                      : "Update Template"
                    }}
                  </h2>
                </div>
              </div>
              <div class="new-template-form">
                <Form @submit="submitForm" :validation-schema="dataList.schema" v-slot="{ errors }" class="row">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                    <label class="form-control-label">Name</label>
                    <Field type="text" name="name" v-model="dataList.formData.name" class="form-control form-control-sm"
                      placeholder="Enter Name" />
                    <span class="text-danger">
                      {{ errors.name || getFormErrors.name }}
                    </span>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                    <label class="form-control-label">Valid From</label>
                    <Field type="date" name="valid_from" v-model="dataList.formData.valid_from"
                      class="form-control form-control-sm" :min="dataList.today" />
                    <span class="text-danger">
                      {{ errors.valid_from || getFormErrors.valid_from }}
                    </span>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                    <label class="form-control-label">Expires On</label>
                    <Field type="date" name="expired_at" :disabled="dataList.formData.valid_from === ''"
                      v-model="dataList.formData.expired_at" class="form-control form-control-sm"
                      :min="minDateForExpiredAt" />
                    <span class="text-danger">
                      {{ errors.expired_at || getFormErrors.expired_at }}
                    </span>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                    <label class="form-control-label">Select Category</label>
                    <Field class="form-control form-control-sm" name="category_id" as="select"
                      v-model="dataList.formData.category_id">
                      <option value="" disabled>Select Category</option>
                      <option v-for="(cat, index) in getTemplateCategory" :key="`template-cat-${index}`" :value="cat.id">
                        {{ cat.name }}
                      </option>
                    </Field>
                    <span class="text-danger">
                      {{ errors.category_id || getFormErrors.category_id }}
                    </span>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-control-label">Description</label>
                    <Field as="textarea" name="description" v-model="dataList.formData.description"
                      class="form-control form-control-sm" placeholder="Enter Description" rows="10" />
                    <span class="text-danger">
                      {{ errors.description || getFormErrors.description }}
                    </span>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-control-label">Content</label>
                    <editor api-key="fz7abntsqhwkempr93qb1r1kpadfxgsv90laxsf7hgasjq3v" :disabled="disabled"
                      id="editor_content" name="content" v-model="dataList.formData.content" :init="{
                        element_format: 'html',
                        height: 400,
                        pthManager: siteUrl(
                          `tinymce?company_id=${selectedCompany.id}`
                        ),
                        menubar: 'insert',
                        branding: false,
                        convert_urls: false,
                        relative_urls: false,
                        image_caption: true,
                        image_advtab: true,
                        min_height: 400,
                        max_height: 650,
                        valid_elements: '+*[*]',
                        paste_as_text: true,
                        pagebreak_separator:
                          '<br style=\'page-break-before:always\'/>',
                        nonbreaking_force_tab: true,
                        content_css: [
                          '/css/paper/paper.min.css?v=' + Math.random(),
                        ],
                        body_id: 'wrapper',
                        plugins: [
                          'autoresize advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen pagebreak',
                          'insertdatetime media table paste code codesample help wordcount preview hr toc',
                        ],
                        external_plugins: {
                          autocomplete:
                            '/tinymce/plugins/autocomplete/plugin.js',
                          WYImageManager:
                            '/tinymce/plugins/wy_image_manager/plugin.js',
                          glossary: '/tinymce/plugins/glossary/plugin.js',
                          CIManager: '/tinymce/plugins/content_item_filter/plugin.js'
                        },
                        toolbar:
                          'fullscreen | undo redo | formatselect | bold italic backcolor | \
                                                                                                          alignleft aligncenter alignright alignjustify | WYImageManager | \
                                                                                                          bullist numlist outdent indent | link s | table | removeformat fullscreen  preview | pagebreak toc glossary | help',
                        setup: function (editor) {
                          //editor.on('BeforeSetContent', function (e){
                          //updateContentItem(e.content)
                          //}),
                          editor.on('focusout', function () {
                            //removeContentItem();
                            getAllContentItem();
                            // updateLocalStroage();
                          });
                        },
                      }" />
                    <p class="sm-text text-mute">
                      Please press @ for content item suggestion
                    </p>
                    <span class="text-danger">
                      {{
                        dataList.customValidation.content ||
                        getFormErrors.content
                      }}
                    </span>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-control-label">Explanation</label>
                    <editor api-key="fz7abntsqhwkempr93qb1r1kpadfxgsv90laxsf7hgasjq3v" :disabled="disabled"
                      name="explanation" v-model="dataList.formData.explanation" :init="{
                        paste_as_text: true,
                        height: 400,
                        menubar: false,
                        body_id: 'wrapper',
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'formatselect | bold italic backcolor | \
                                                                                                      alignleft aligncenter alignright alignjustify | \
                                                                                                      bullist numlist outdent indent | link s | removeformat | help',
                        setup: function (editor) {
                          editor.on('focusout', function () {
                            // setInput()
                          });
                        },
                      }" />
                    <span class="text-danger">
                      {{
                        dataList.customValidation.explanation ||
                        getFormErrors.explanation
                      }}
                    </span>
                  </div>

                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                    <label class="form-control-label">Links</label>
                    <editor api-key="fz7abntsqhwkempr93qb1r1kpadfxgsv90laxsf7hgasjq3v" :disabled="disabled"
                      v-model="dataList.formData.links" :init="{
                        paste_as_text: true,
                        height: 400,
                        menubar: false,
                        body_id: 'wrapper',
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount',
                        ],
                        toolbar:
                          'formatselect | bold italic backcolor | \
                                                                                                  alignleft aligncenter alignright alignjustify | \
                                                                                                  bullist numlist outdent indent | link s | removeformat | help',
                        setup: function (editor) {
                          editor.on('focusout', function () {
                            //setInput()
                          })
                        },
                      }" />
                  </div>

                  <div v-if="$route.name === 'create-template'" class="form-group row m-t-md">
                    <div class="col-sm-offset-2 col-sm-10">
                      <button type="submit" :disabled="!data.permission.canCreate" class="btn btn-green">
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div v-if="id" class="table-box p-3 mb-3">
              <router-link v-if="data.permission.canEdit" :to="`/library/template/items/${id}`"
                class="btn btn-green mb-3">
                Show Lists
              </router-link>
            </div>
          </div>
          <div v-if="$route.name === 'update-template'" class="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div v-if="dataList.formData.status != 'Approve' &&
              dataList.formData.canEdit
              " class="table-box p-3 mb-3">
              <div class="form-group mb-3">
                <label class="col-sm-2 form-control-label">Remarks</label>
                <div>
                  <textarea class="form-control" v-model="dataList.formData.user_remarks" rows="2">
                  </textarea>
                </div>
              </div>
              <div class="d-flex">
                <approver-button-group 
                  :show="id ? true : dataList.canEdit" 
                  :can-edit="data.permission.canEdit"
                  :form="dataList.formData" 
                  :groups="{
                    author: {
                      id: null,
                      lists: dataList.formData.author,
                    },
                    reviewer: {
                      id: null,
                      lists: dataList.formData.reviewer,
                    },
                    approver: {
                      id: null,
                      lists: dataList.formData.approver,
                    },
                  }" 
                  @submitForm="submitFromApproverButton" 
                />
              </div>
            </div>
            <div class="table-box p-3 mb-3" v-if="!getProfile.superAdmin">
              <p class="border-bottom mb-2 pb-2">
                Status:
                <span class="fw-bold">{{ dataList.formData.status }}</span>
              </p>
              <approver-group 
                :author="{
                  id: dataList.formData.author_role_id,
                  role_name: 'Document Author',
                  lists: dataList.formData.author,
                }" 
                :reviewer="{
                  id: dataList.formData.reviewer_role_id,
                  role_name: 'Document Reviewer',
                  lists: dataList.formData.reviewer,
                }" 
                :approver="{
                  id: dataList.formData.approver_role_id,
                  role_name: 'Document Approver',
                  lists: dataList.formData.approver,
                }" 
                :id="id" 
                :canEdit="dataList.formData.canEdit" 
                :status="dataList.formData.status"
                :show="['review', 'approve']" 
                @buttonSuccess="deleteUser" 
                @approverGroupUpdate="approverGroupUpdate" 
              />
            </div>
            <template-discussion :id="id" :can-edit="data.permission.canEdit" :form-data="dataList.formData" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Form, Field } from "vee-validate";
import { computed, onMounted, reactive, watch, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import HelperFunction from "@/common/helpers";
import * as Yup from "yup";
import TemplateDiscussion from "@/views/auth/library/template/TemplateDiscussion";
import InviteUser from "@/common/components/invite-user/Index";
import ApproverButtonGroup from "@/common/components/button-groups";
import moment from "moment";
import ApproverGroup from "@/common/components/approver-group";

export default {
  name: "CreateTemplate",
  components: {
    ApproverButtonGroup,
    InviteUser,
    TemplateDiscussion,
    Form,
    Field,
    ApproverGroup,
  },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  setup(props) {
    const {
      siteUrl,
      selectedCompany,
      getTemplateCategory,
      showToast,
      formPermissionResolver,
      getFormErrors,
      data,
    } = HelperFunction();
    const router = useRouter();
    const store = useStore();
    const toast = inject('toast');

    /** form permission **/
    formPermissionResolver("/library/template");
    /** end **/

    let dataList = reactive({
      schema: Yup.object().shape({
        name: Yup.string().required(),
        valid_from: Yup.string().required("Valid Form is required"),
        expired_at: Yup.string().required("Expired at is required"),
        category_id: Yup.string().required("Category is required"),
        description: Yup.string().required(),
      }),
      today: "",
      customValidation: {},
      inviteUserUrl: "",
      contentItems: [],
      showInviteForm: false,
      invitationType: "",
      formData: {
        author: [],
        reviewer: [],
        approver: [],
        user_remarks: "",
        name: "",
        description: "",
        content: "",
        explanation: "",
        valid_from: "",
        expired_at: "",
        category_id: "",
        status: "",
        links: "",
        company_id: "",
        contentItems: [],
      },
    });

    onMounted(() => {
      dataList.today = moment(new Date()).format("YYYY-MM-DD");
    });

    /** watch **/
    watch(
      () => props.id,
      (value) => {
        if (value) {
          dataList.schema = null;
          let payload = {
            action: value,
            params: {
              company_id: selectedCompany.value.id
                ? selectedCompany.value.id
                : null,
            },
          };
          store
            .dispatch("getTemplate", payload)
            .then((resp) => {
              const data = resp.data.data;
              dataList.formData = { ...dataList.formData, ...data };
              dataList.contentItems = data.items ? data.items : [];
              // showToast(resp);
            })
            .catch((error) => {
              showToast(error);
            });
        }
      },
      { immediate: true }
    );
    /** watch end**/

    /**computed**/
    const getTemplateContentItems = computed(
      () => store.getters.getTemplateContentItems
    );

    const getProfile = computed(() => store.getters.getProfile);
    /**computed**/

    const minDateForExpiredAt = computed(() => {
      return moment(dataList.formData.valid_from)
        .add(1, "days")
        .format("YYYY-MM-DD");
    });

    /** methods **/
    const submitForm = async ($parm, $comment) => {
      dataList.customValidation = {};
      if (
        dataList.formData.content === "" ||
        dataList.formData.explanation === ""
      ) {
        dataList.customValidation.content =
          dataList.formData.content === "" ? "Content is required" : "";
        dataList.customValidation.explanation =
          dataList.formData.explanation === "" ? "Explanation is required" : "";
        return;
      }

      dataList.formData.company_id = selectedCompany.value.id
        ? selectedCompany.value.id
        : null;
      dataList.formData.contentItems =
        getTemplateContentItems.value.length > 0
          ? getTemplateContentItems.value
          : dataList.contentItems;
      let payload = {
        action: props.id ? props.id : null,
        formData: dataList.formData,
      };
      if (props.id) {
        if (!data.permission.canEdit) return false;
        await updateForm(payload);
      } else {
        if (!data.permission.canCreate) return false;
        await storeForm(payload);
      }
    };

    async function submitFromApproverButton($parm, $comment) {
      let citems = getTemplateContentItems.value;
      let has_reject_item = false;
      for (const key in citems) {
        if (citems[key].is_reject) {
          has_reject_item = true;
        }
      }

      if (getProfile.value.superAdmin || (dataList.formData.reviewer.length > 0 && dataList.formData.approver.length > 0 && has_reject_item === false)) {
        dataList.formData.status = $parm;
        dataList.formData.comment = $comment;
        await submitForm();
      }
      else {

        let toastData = {
          message: (has_reject_item) ? 'You have rejected one or more content item' : 'Please add a reviewer and an approver!',
          status: 400,
          data: false,
          response: {
            status: 400
          }
        }
        toast.error(toastData.message);
      }
    }

    async function storeForm(payload) {
      await store
        .dispatch("storeTemplateFrom", payload)
        .then((response) => {
          store.dispatch("setTemplateContentItems", []);
          showToast(response);
          router.push(`/library/template`);
        })
        .catch((error) => {
          if (error.response) {
            data.formError = error.response.data.errors;
          }
        });
    }

    async function updateForm(payload) {
      await store
        .dispatch("updateTemplateFrom", payload)
        .then((response) => {
          store.dispatch("reloadStaticData");
          store.dispatch("setTemplateContentItems", []);
          showToast(response);
          router.push(`/library/template`);
        })
        .catch((error) => {
          showToast(error);
        });
    }

    const getAllContentItem = () => {
      let cis = [];
      let arrMatch = null;
      let rePattern = new RegExp(/\[\[([\w\W]+?)]]/, "gi");

      while ((arrMatch = rePattern.exec(dataList.formData.content))) {
        if (arrMatch[1] != arrMatch[1].toUpperCase()) {
          cis.push(arrMatch[1]);
        } else {
          const haveOne = dataList.contentItems.some(function (el) {
            return el.identifier === arrMatch[1] ? true : false;
          });
          if (!haveOne) {
            let obj = {
              sl: dataList.contentItems.length + 1,
              identifier: arrMatch[1],
            };
            dataList.contentItems.push(obj);
          }
        }
      }
    };

    function approverGroupUpdate(name, params) {
      showInviteForm(params.type ?? "", params.role ?? '', params.roleName ?? '');
    }

    const showInviteForm = (type = "", role, roleName) => {
      dataList.invitationType = type;
      if (type) {
        dataList.inviteUserUrl = `${vueConfig.Library.TemplateApproversEndPoint}/${props.id}`;
        dataList.inviteUserParams = {
          company_id: selectedCompany.value.id
            ? selectedCompany.value.id
            : null,
          role_id: role,
          role_name: roleName,
        };
      }
      dataList.showInviteForm = !dataList.showInviteForm;
    };

    const approverGroupSuccess = async (value) => {
      if (value != "cancel") {
        let payload = {
          action: props.id,
          formData: value,
        };
        await store
          .dispatch("storeTemplateApprover", payload)
          .then((res) => {
            const result = res.data.data;
            
            if (result.type === "author") {
              dataList.formData.author = result.users;
            }
            else if (result.type === "reviewer") 
            {
              dataList.formData.reviewer = result.users;
            } 
            else 
            {
              dataList.formData.approver = result.users;
            }
            showToast(res);
          })
          .catch((error) => {
            showToast(error);
          });
      }
      showInviteForm();
    };

    const deleteUser = async (userType, keyVal, type) => {
      if (type === "delete") {
        if (userType === "Author") {
          dataList.formData.author.splice(keyVal, 1);
        } else if (userType === "Review") {
          dataList.formData.reviewer.splice(keyVal, 1);
        } else if (userType === "Approve") {
          dataList.formData.approver.splice(keyVal, 1);
        }
      } else {
        if (userType === "author") {
          dataList.formData.author[keyVal].status = "Re Invited";
        } else if (userType === "reviewer") {
          dataList.formData.reviewer[keyVal].status = "Re Invited";
        } else if (userType === "approver") {
          dataList.formData.approver[keyVal].status = "Re Invited";
        }
      }
    };

    /** methods ends**/

    return {
      data,
      dataList,
      submitForm,
      siteUrl,
      selectedCompany,
      getTemplateCategory,
      getAllContentItem,
      showInviteForm,
      approverGroupSuccess,
      submitFromApproverButton,
      deleteUser,
      getFormErrors,
      minDateForExpiredAt,
      getProfile,
      approverGroupUpdate,
    };
  },
};
</script>
